<template>
    <el-drawer title="详情" :visible.sync="show" direction="rtl" size="55%">
        <div class="content">
            <el-descriptions direction="vertical" :column="4" title="代理商信息" border>
                <el-descriptions-item label="名称">111</el-descriptions-item>
                <el-descriptions-item label="抽佣比例">111</el-descriptions-item>
                <el-descriptions-item label="状态">111</el-descriptions-item>
                <el-descriptions-item label="管理员">111</el-descriptions-item>
            </el-descriptions>
        </div>

        <div class="content">
            <h3>员工列表</h3>
            <el-table :data="list">
                <el-table-column prop="id" label="ID" min-width="120"></el-table-column>
                <el-table-column prop="account" label="账号" min-width="120"></el-table-column>
                <el-table-column prop="status_dsc" label="状态" min-width="120">
                    <template slot-scope="{ row }">
                        <el-tag type="success" v-if="row.status === 20">{{ row.status_dsc }}</el-tag>
                        <el-tag type="danger" v-else>{{ row.status_dsc }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="level_dsc" label="类型" min-width="120"></el-table-column>
                <el-table-column prop="mobile" label="手机号码" min-width="120"></el-table-column>
                <el-table-column prop="position" label="职位" min-width="120"></el-table-column>
                <el-table-column prop="remark" label="备注" min-width="120"></el-table-column>
            </el-table>
            <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize"
                        @pagination="getList"/>
        </div>
    </el-drawer>
</template>

<script>
import {listAgentAPI} from "@/views/agent/employee/api";
import {EMPLOYEE} from '@/enum/agent_dict'

export default {
    name: 'detail',
    data() {
        return {
            show: false,
            total: 0,
            currentId: 0,
            searchData: {
                page: 1,
                pagesize: 10,
                name: '',
                agent_id: 0,
                level: EMPLOYEE,
            },
            list: [],
        }
    },

    methods: {
        async open(id) {
            this.show = true
            this.searchData.agent_id = id
            await this.getList()
        },

        async getList() {
            const res = await listAgentAPI(this.searchData)
            this.list = res.data || []
            this.total = res.total || 0
        },
    }
}
</script>

<style scoped lang="scss">
.content {
    padding: 20px;

    h3 {
        padding: 10px 0;
    }
}
</style>
