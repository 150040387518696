<template>
    <el-dialog
        title="新增or修改"
        :visible="dialogVisible"
        :modal="false"
        width="40%"
        :before-close="handleClose">
        <el-form ref="form" label-width="100px" :rules="rules" :model="formData">
            <el-form-item label="代理商名称" prop="name">
                <el-input v-model="formData.name"></el-input>
            </el-form-item>
            <el-form-item label="折扣" prop="commission_rate">
                <el-input v-model="formData.commission_rate"></el-input>
            </el-form-item>
            <el-form-item label="代理商账号" prop="agent_account" v-if="id === 0">
                <el-input v-model="formData.agent_account"></el-input>
            </el-form-item>
            <el-form-item label="代理商密码" prop="agent_password" v-if="id === 0">
                <el-input v-model="formData.agent_password"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input v-model="formData.remark" type="textarea"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="addOrEdit('form')">确定</el-button>
                <el-button @click="handleClose">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
import {addAPI, editAPI, detailAPI} from '../api'

export default {
    name: 'addOrEdit',
    data() {
        return {
            formData: {
                id: 0,
                name: '',
                commission_rate: 0,
                remark: '',
                agent_account: '',
                agent_password: '',
            },
            rules: {
                name: [
                    {trigger: 'blur', message: '请输入名称', required: true}
                ],
                commission_rate: [
                    {trigger: 'blur', message: '请输入价格', required: true},
                    { pattern: /^[0-9]+.?[0-9]{1,2}?$/ , message: '抽佣比例为数字', trigger: "blur"},
                ],
                agent_account: [
                    {trigger: 'blur', message: '请输入账号', required: true}
                ],
                agent_password: [
                    {trigger: 'blur', message: '请输入密码', required: true}
                ],
            },
        }
    },

    props: {
        dialogVisible: {
            type: Boolean,
            default() {
                return 0
            },
        },
        id: {
            type: Number,
            default() {
                return 0
            }
        }
    },

    watch: {
        id: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.getDetail(newVal)
                }
            }
        }
    },

    methods: {
        validateInteger(rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入数字'));
            } else {
                const intValue = parseInt(value, 10);
                if (isNaN(intValue)) {
                    callback(new Error('请输入有效的整数'));
                } else if (intValue <= 0) {
                    callback(new Error('请输入大于0的整数'));
                } else {
                    callback();
                }
            }
        },

        handleClose() {
            this.$emit('close-form')
        },

        async getDetail(id) {
            this.formData = await detailAPI({id})
        },

        addOrEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.formData.id) {
                        editAPI(this.formData).then(() => {
                            this.$message.success('编辑成功')
                            this.$emit('modify-success')
                        })
                    } else {
                        addAPI(this.formData).then(() => {
                            this.$message.success('新增成功')
                            this.$emit('modify-success')
                        })
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    }
}
</script>


<style scoped lang="scss">

</style>
